import React from 'react'
import { Link, graphql } from 'gatsby'
import SEO from '../components/seo'

export default ({ data }) => {
  const title= `Archive`

  return (
    <>
      <SEO title={title} />
      <h2>{title}</h2>
      <table style={{ width: '100%' }}>
        <ul>
          {data.allMarkdownRemark.edges.map(({ node }, index) => (
            <li key={index}>
              <Link to={node.fields.slug}>{node.frontmatter.title}</Link>
              {/* <em style={{marginLeft: 10, color: 'rgba(0,0,0,.6)'}}>{node.frontmatter.date}</em> */}
            </li>
          ))}
        </ul>
      </table>
    </>
  )
}

export const query = graphql`
  query {
    allMarkdownRemark(sort: {fields: [frontmatter___date], order: DESC}, limit: 10) {
      totalCount
      edges {
        node {
          id
          excerpt
          frontmatter {
            date
            title
          }
          fields {
            slug
          }
          html
          timeToRead
        }
      }
    }
  }
`
